// import { useAppSelector } from "../../../store/hooks";
import { Employee } from "../../../../types/ganttChart";
import VerticalTextRow from "../VerticalTextRow";
import ganttChartStyles from "../../../../styles/ganttChart/ganttChart.module.css";
import KpiWrapper from "../../KpiWrapper";
import VerticalGanttChartTransportationIcon from "../VerticalGanttChartTransportationIcon";
import ViolationChip from "../../../UI/ViolationChip";
import CollapsedKpiRow from "./CollapsedKpiRow";
import React from "react";
import { useAppSelector } from "../../../../store/hooks";

const KpiRows = ({
  employees,
  isSticky,
}: {
  employees: Employee[];
  isSticky?: boolean;
}) => {
  const { verticalColumnWidth } = useAppSelector(
    (state) => state.ganttChartSlice
  );
  const getContinuityArray = () => {
    // if (selectedGanttChartTab === "Nexus") {
    return employees.map((employee, index) =>
      employee.shiftSchedule.kpi.continuity != null ? (
        <KpiWrapper
          value={Math.round(employee.shiftSchedule.kpi.continuity * 10) / 10}
          showColoredChip
          key={index}
        />
      ) : (
        ""
      )
    );
  };

  const getDistanceArray = () => {
    // if (selectedGanttChartTab === "Nexus") {
    return employees.map((employee, index) => (
      <KpiWrapper
        value={Math.round(
          employee.shiftSchedule.kpi.travelDistanceInMeters / 1000
        )}
        key={index}
      >
        <VerticalGanttChartTransportationIcon
          type={employee.transportationType}
          color="var(--col-black)"
          size={10}
        />
      </KpiWrapper>
    ));
  };

  const getViolationsArray = () => {
    // if (selectedGanttChartTab === "Nexus") {
    return employees.map((employee, index) =>
      employee.shiftSchedule.kpi.violations &&
      employee.shiftSchedule.kpi.violations.length > 0 ? (
        <KpiWrapper
          value={employee.shiftSchedule.kpi.violations.length}
          key={index}
        >
          <ViolationChip height={14} width={14} />
        </KpiWrapper>
      ) : (
        ""
      )
    );
  };

  const getCollapsedArray = () => {
    const collectedArray = [];
    for (let i = 0; i < employees.length; i++) {
      collectedArray.push(
        <React.Fragment key={`fragment-${i}`}>
          {verticalColumnWidth > 90 && (
            <KpiWrapper
              id="continuity"
              value={
                Math.round(employees[i].shiftSchedule.kpi.continuity * 10) / 10
              }
              showColoredChip
              key={`continuity-${1}`}
            />
          )}
          {verticalColumnWidth > 150 && (
            <KpiWrapper
              id="distance"
              value={Math.round(
                employees[i].shiftSchedule.kpi.travelDistanceInMeters / 1000
              )}
              key={`transport-${i}`}
            >
              <VerticalGanttChartTransportationIcon
                type={employees[i].transportationType}
                color="var(--col-black)"
                size={10}
              />
            </KpiWrapper>
          )}

          {employees[i].shiftSchedule.kpi.violations &&
          employees[i].shiftSchedule.kpi.violations.length > 0 ? (
            <KpiWrapper
              id="violations"
              value={employees[i].shiftSchedule.kpi.violations.length}
              key={`violations-${i}`}
            >
              <ViolationChip height={14} width={14} />
            </KpiWrapper>
          ) : (
            ""
          )}
        </React.Fragment>
      );
    }
    return collectedArray;
  };

  return (
    <>
      <section
        className={`${ganttChartStyles.verticalTextRow} ${isSticky ? ganttChartStyles.foldMultipleKpi : ganttChartStyles.unfoldMultipleKpi}`}
      >
        <>
          <VerticalTextRow
            title="Kontinuitet"
            array={getContinuityArray()}
          ></VerticalTextRow>
          <VerticalTextRow
            title="Kørsel (km)"
            array={getDistanceArray()}
          ></VerticalTextRow>
          <VerticalTextRow
            title="Regelbrud"
            array={getViolationsArray()}
          ></VerticalTextRow>
        </>
      </section>
      <section
        className={`${ganttChartStyles.verticalTextRow} ${!isSticky ? ganttChartStyles.foldCollapsedKpi : ganttChartStyles.unfoldCollapsedKpi}`}
      >
        <VerticalTextRow title="" array={getCollapsedArray()}></VerticalTextRow>
        {/* <CollapsedKpiRow array={getCollapsedArray()} /> */}
      </section>
    </>
  );
};

export default KpiRows;

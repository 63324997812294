import ganttChartService from "../../../service/ganttChartService";
import { useAppSelector } from "../../../store/hooks";
import { Employee } from "../../../types/ganttChart";
import { ganttChartVerticalValues } from "../../../utils/ganttChartValues";
import VerticalOperationalTask from "./VerticalOperationalTasks";
import VerticalGanttChartTransportationTask from "./VerticalGanttChartTransportationTask";
import VerticalWorkTask from "./VerticalWorkTask";
import UnplannedTaskChartPositionAllocationService from "../../../service/UnplannedTaskChartPositionAllocationService";

const VerticalGanttChartTaskWrapper = ({
  xPosition,
  employee,
}: {
  xPosition: number;
  employee: Employee;
}) => {
  const { verticalColumnWidth } = useAppSelector(
    (state) => state.ganttChartSlice,
  );
  const { timelineRange, pixelPerMinute } = useAppSelector(
    (state) => state.timelineSlice,
  );

  const getYPosition = (startTime: string) => {
    return ganttChartVerticalValues.findTaskYPosition(
      timelineRange!,
      pixelPerMinute,
      startTime,
    );
  };

  const getHeight = (startTime: string, endTime: string) => {
    return (
      ganttChartService.timeDifferenceInMinutes(startTime, endTime) *
      pixelPerMinute
    );
  };

  /* Cluster tasks and set ganttChartIndex for each */
  const overlappingClustersOfOperationalTasks =
    UnplannedTaskChartPositionAllocationService.clusterOverlappingTasks(
      employee.tasks.operationalTasks,
      (task) => new Date(task.start),
      (task) => new Date(task.end),
    );

  /* Sort tasks according to ganttChartIndex, as svg files are drawn in an order.
   This ensures that smaller tasks are shown on top. Cannot be handled with css on svg elements. */
  const sortedArrayOfClusteredOperationalTasks =
    overlappingClustersOfOperationalTasks.sort(
      (a, b) => a.ganttChartPosition - b.ganttChartPosition,
    );

  return (
    <>
      <>
        {employee.tasks.workTasks.map((task, index) => (
          <VerticalWorkTask
            employee={employee}
            key={"nexus-worktask" + index}
            xPosition={xPosition}
            task={task}
            yPosition={getYPosition(task.start)}
            width={ganttChartVerticalValues.getTaskWidth(verticalColumnWidth)}
            height={getHeight(task.start, task.end)}
          />
        ))}

        {sortedArrayOfClusteredOperationalTasks.map((task, index) => (
          <VerticalOperationalTask
            employee={employee}
            key={"nexus-operationaltask" + index}
            xPosition={xPosition}
            task={task}
            yPosition={getYPosition(task.start)}
            width={ganttChartVerticalValues.getTaskWidth(verticalColumnWidth)}
            height={getHeight(task.start, task.end)}
          />
        ))}

        {employee.tasks.transportationTasks.map((task, index) => (
          <VerticalGanttChartTransportationTask
            employee={employee}
            key={"nexus-transportationtask" + index}
            xPosition={xPosition}
            task={task}
            yPosition={getYPosition(task.start)}
            transportationType={employee.transportationType}
            width={ganttChartVerticalValues.getTaskWidth(verticalColumnWidth)}
          />
        ))}
      </>
    </>
  );
};

export default VerticalGanttChartTaskWrapper;

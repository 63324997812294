import { KpiOther } from "../../types/kpi";
import labels from "../../utils/labels";
import KPITextLine from "../UI/kpi/KPITextLine";
import KpiCard from "./KpiCard";
import { ReactComponent as GraphBarsIcon } from "../../assets/icons/graph_bars.svg";
import ComparisonChip from "../UI/kpi/ComparisonChip";

const KeyNumbersCard = ({
  kpiList,
  compare,
  compareKpiList,
}: {
  kpiList: KpiOther;
  compare: boolean;
  compareKpiList: KpiOther;
}) => {
  return (
    <KpiCard
      title={labels.keyNumbers}
      icon={<GraphBarsIcon />}
      classes="w-[40%]"
    >
      <KPITextLine
        compare={compare}
        title="Planlagte medarbejdere"
        value={`${kpiList.plannedEmployees.toString()} ud af ${kpiList.totalEmployees.toString()}`}
      >
        {compare && (
          <ComparisonChip
            positiveIndicator="NEGATIVE"
            value={kpiList.plannedEmployees - compareKpiList.plannedEmployees}
            id={compareKpiList.plannedEmployees}
          />
        )}
      </KPITextLine>
      <KPITextLine
        compare={compare}
        title="Uplanlagte besøg"
        value={`${kpiList.unplannedVisits.toString()} ud af ${kpiList.totalVisits.toString()}`}
      >
        {compare && (
          <ComparisonChip
            positiveIndicator="NEGATIVE"
            value={
              kpiList.unplannedCalendarEvents -
              compareKpiList.unplannedCalendarEvents
            }
            id={compareKpiList.unplannedCalendarEvents}
          />
        )}
      </KPITextLine>
      <KPITextLine
        compare={compare}
        title="Kørsel"
        value={`${kpiList.drivenKilometers.toString()} km`}
      >
        {compare && (
          <ComparisonChip
            positiveIndicator="NEGATIVE"
            value={Math.round(
              kpiList.drivenKilometers - compareKpiList.drivenKilometers
            )}
            id={compareKpiList.drivenKilometers}
            unit="km"
          />
        )}
      </KPITextLine>
    </KpiCard>
  );
};

export default KeyNumbersCard;

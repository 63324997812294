import Toast from "./ToastNotification";
import toastStyle from "../../styles/toaster.module.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ToastNotification } from "../../types/errors";
import { removeNotification } from "../../store/toastNotifications";
import { useEffect } from "react";

const ToastWrapper = () => {
  const dispatch = useAppDispatch();
  const { notifications } = useAppSelector((state) => state.toastNotifications);

  const removeSpecificError = (error: ToastNotification) => {
    dispatch(removeNotification(error));
  };

  useEffect(() => {
    console.log("it updayted");
  }, [notifications]);
  return (
    <>
      {notifications && (
        <div className={toastStyle.toastWrapper}>
          {notifications.map((error) => (
            <Toast title="Error" removeError={() => removeSpecificError(error)}>
              {error.message}
            </Toast>
          ))}
        </div>
      )}
    </>
  );
};

export default ToastWrapper;
